import { useEffect, useRef, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import Modal from "../components/Modal";
import EmployeEdit from "../components/EmployeEdit";
import { useAuth } from "../context/useAuth";
import Papa from "papaparse";
import ModalImportResult from "../components/ModalImportResult";
import SidebarFilterEmployees from "../components/SidebarFilterEmployees";

export default function EmployeList() {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState();
  const [editItem, setEditItem] = useState();
  const [loading, setLoading] = useState();
  const [loadingImport, setLoadingImport] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [showModalResult, setShowModalResult] = useState(false);
  const { checkPermission } = useAuth();
  const inputFile = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    let data = await api.getEmployees();
    setLoading(false);
    setList(data.data);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const config = {
    onClickRow: (item, index) => {
      setEditItem(item);
      setSelectedIndex(index);
      setShowModalEdit(true);
    },
    columns: [
      {
        key: "email",
        header: "Email",
        template: (item) => <span className="main-row">{item.email || "NO EMAIL"}</span>,
      },
      {
        header: "First Name",
        key: "firstName",
      },
      {
        header: "Last Name",
        key: "lastName",
      },
      {
        header: "HR Code",
        key: "hrCode",
      },
      {
        header: "HR Alias",
        key: "hrAlias",
      },
      {
        header: "Team",
        key: "teamName",
      },
      {
        header: "Position",
        key: "position",
      },
      {
        header: "Location",
        key: "location",
      },
      {
        header: "Division",
        key: "divisionName",
      },
      {
        header: "Currency",
        key: "currencyCode",
      },
    ],
  };


  const setFilterValue = (key, value) => {
    setFilter((filter) => {
      let set = {};
      set[key] = value;
      return { ...filter, ...set };
    });
  };

  const syncBos = async () => {
    let data = await api.syncBos();
    if (data.ok) {
      alert("Sync complete.\n Created: " + data.data.created + "\n Edited: " + data.data.edited);
    } else {
      alert("Sync error");
    }
  };

  const exportEmployees = async () => {
    let listText = list.map((item) => {
      item.history = item.history && JSON.stringify(item.history);
      item.notes = item.notes && JSON.stringify(item.notes);
      return item;
    });

    var csv = Papa.unparse(listText);
    downloadCsv(csv, `HRB - Export All Employees`);
  };

  const downloadCsv = (data, fileName) => {
    const csvData = new Blob([data], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const importEmployees = async () => {
    inputFile.current.click();
  };

  const upload = (e) => {
    e.preventDefault();

    let files = Object.values(e.target.files);
    setLoadingImport(files);

    inputFile.current.value = null;
    inputFile.current.files = null;

    for (let f in files) {
      let file = files[f];
      const fileReader = new FileReader();

      fileReader.onload = async function (event) {
        let text = event.target.result;

        // Parse JSON
        let array = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
        });

        let request = await api.importEmployees(array.data);
        if (request.ok) {
          setShowModalResult(request.data);
        } else {
          alert("Error importing employees");
        }
        setLoadingImport(false);
        fetchData();
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <>
      <div className={"page-wrapper"}>
        <div className="btn-bar">
          <div className="btn-bar-left">
            {checkPermission("syncBos") && (
              <button className="green" onClick={syncBos}>
                Sync BOS
              </button>
            )}

            <button className="green" onClick={exportEmployees}>
              Export
            </button>

            {checkPermission("importEmployees") && (
              <>
                <button className="green" disabled={loadingImport} onClick={importEmployees}>
                  {loadingImport ? "Loading..." : "Import"}
                </button>

                <input type="file" accept=".csv" ref={inputFile} multiple={true} onChange={upload} style={{ display: "none" }} />
              </>
            )}
          </div>
          <div className="btn-bar-right">
            {!showSidebar && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div className="table-container">
          <Table config={config} filter={filter} data={list} loading={loading}></Table>
        </div>
      </div>

      {showModalResult && (
        <ModalImportResult showModalResult={showModalResult} setShowModalResult={setShowModalResult}></ModalImportResult>
      )}

      {showModalEdit && (
        <Modal close={() => setShowModalEdit(false)}>
          <EmployeEdit
            employee={editItem}
            list={list}
            setList={setList}
            index={selectedIndex}
            close={() => setShowModalEdit(false)}
          ></EmployeEdit>
        </Modal>
      )}

      <SidebarFilterEmployees
        filter={filter}
        setFilter={setFilter}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        setFilterValue={setFilterValue}
      ></SidebarFilterEmployees>

    </>
  );
}
