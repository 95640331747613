import { useEffect, useState } from "react";
import api from "../services/api";
import moment from "moment";
import Budget from "../pages/Budget";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useAuth } from "../context/useAuth";

export default function EmployeEdit({ employee, index, list, setList, close }) {
  const [employe, setEmploye] = useState({ ...employee });
  const [editMode, setEditMode] = useState();
  const [selectedTab, setSelectedTab] = useState("info");
  const { user, checkPermission } = useAuth();
  const [note, setNote] = useState();
  const [listAutocomplete, setListAutocomplete] = useState(
    list
      ? list.map((e) => {
        return { value: e.hrCode, name: e.hrCode + " - " + e.firstName + " " + e.lastName };
      })
      : []
  );

  useEffect(() => {
    const fetchData = async () => {
      let data = await api.getEmployees();
      setListAutocomplete(
        data.data.map((e) => {
          return { value: e.hrCode, name: e.hrCode + " - " + e.firstName + " " + e.lastName };
        })
      );
    };

    if (!list && user) fetchData();
    // eslint-disable-next-line
  }, [list, user]);

  const setValue = (key, value) => {
    employe[key] = value;
    setEmploye({ ...employe });
  };

  const save = async () => {
    let data = await api.saveEmploye(employe);

    if (data.status === 200 && list) {
      list[index] = data.data;
      setList([...list]);
    }
  };

  const addNote = async () => {
    let data = await api.addNote(employe.id, note);

    if (data.status === 200) {
      setEmploye(data.data);
      setNote("");
      if (list) {
        list[index] = data.data;
        setList([...list]);
      }
    }
  };

  return (
    <div className="modal-edit-employee">
      <div className="modal-header">
        <div>
          {employe.hrCode} - {employe.firstName} {employe.lastName}
        </div>
        <div className="modal-tab-bar">
          <div className={"modal-tab" + (selectedTab === "info" ? " active" : "")} onClick={() => setSelectedTab("info")}>
            Info
          </div>
          <div className={"modal-tab" + (selectedTab === "history" ? " active" : "")} onClick={() => setSelectedTab("history")}>
            History
          </div>
          <div
            className={"modal-tab" + (selectedTab === "productFocus" ? " active" : "")}
            onClick={() => setSelectedTab("productFocus")}
          >
            Product Focus
          </div>
          <div className={"modal-tab" + (selectedTab === "budget" ? " active" : "")} onClick={() => setSelectedTab("budget")}>
            Budget
          </div>
          {checkPermission("viewNote") &&
            <div className={"modal-tab" + (selectedTab === "notes" ? " active" : "")} onClick={() => setSelectedTab("notes")}>
              Notes
            </div>
          }
        </div>
      </div>

      <div className="modal-body">
        {selectedTab === "info" && (
          <div>
            <div className="subtitle">Employee Info</div>

            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>First Name</label>
                  <input value={employe.firstName} onChange={(e) => setValue("firstName", e.target.value)} disabled></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>Last Name</label>
                  <input value={employe.lastName} onChange={(e) => setValue("lastName", e.target.value)} disabled></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Email</label>
                <input value={employe.email} onChange={(e) => setValue("email", e.target.value)} disabled></input>
              </div>
            </div>

            {/* BOS */}
            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>HR Code</label>
                  <input value={employe.hrCode} onChange={(e) => setValue("hrCode", e.target.value)} disabled></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>HR Alias</label>
                  <input value={employe.hrAlias} onChange={(e) => setValue("hrAlias", e.target.value)} disabled></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>TW Office</label>
                  <input value={employe.twOffice} onChange={(e) => setValue("twOffice", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Team</label>
                  <input value={employe.teamName} onChange={(e) => setValue("teamName", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Payroll Source</label>
                  <input
                    value={employe.payrollSource}
                    onChange={(e) => setValue("payrollSource", e.target.value)}
                    disabled
                  ></input>
                </div>
                <div className="label-row">
                  <label>Currency</label>
                  <input value={employe.currencyCode} onChange={(e) => setValue("currencyCode", e.target.value)} disabled></input>
                </div>

                <div className="label-row">
                  <label>Start Date</label>
                  <input
                    value={employe.startDate ? moment(employe.startDate).format("DD MMMM YYYY") : ""}
                    onChange={(e) => setValue("startDate", e.target.value)}
                    disabled
                  ></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>Department</label>
                  <input
                    value={employe.departmentName}
                    onChange={(e) => setValue("departmentName", e.target.value)}
                    disabled
                  ></input>
                </div>
                <div className="label-row">
                  <label>Division</label>
                  <input value={employe.divisionName} onChange={(e) => setValue("divisionName", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Position</label>
                  <input value={employe.position} onChange={(e) => setValue("position", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Location</label>
                  <input value={employe.location} onChange={(e) => setValue("location", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>End Date</label>
                  <input
                    value={employe.endDate ? moment(employe.endDate).format("DD MMMM YYYY") : ""}
                    onChange={(e) => setValue("endDate", e.target.value)}
                    disabled
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Pay Supervisor</label>

                {!editMode ? (
                  <>
                    {checkPermission("editUser") && (
                      <>
                        {employe._paySupervisor && (
                          <button className="small link ml-15">
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setValue("_paySupervisor", null);
                                save();
                              }}
                            >
                              close
                            </span>
                          </button>
                        )}
                      </>
                    )}

                    <input
                      value={
                        employe._paySupervisor
                          ? listAutocomplete.find((item) => item.value?.toString() === employe._paySupervisor?.toString())?.name
                          : ""
                      }
                      disabled
                    ></input>
                  </>
                ) : (
                  <div className="autocomplete-wrapper">
                    <ReactSearchAutocomplete
                      value={employe._paySupervisor}
                      items={listAutocomplete}
                      autoFocus
                      onSelect={(e) => {
                        setValue("_paySupervisor", e.value);
                      }}
                    />

                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Pay Advisor</label>

                {!editMode ? (
                  <>
                    {checkPermission("editUser") && (
                      <>
                        {employe._payAdvisor && (
                          <button className="small link ml-15">
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setValue("_payAdvisor", null);
                                save();
                              }}
                            >
                              close
                            </span>
                          </button>
                        )}
                      </>
                    )}

                    <input
                      value={
                        employe._payAdvisor
                          ? listAutocomplete.find((item) => item.value?.toString() === employe._payAdvisor?.toString())?.name
                          : ""
                      }
                      disabled
                    ></input>
                  </>
                ) : (
                  <div className="autocomplete-wrapper">
                    <ReactSearchAutocomplete
                      value={employe._payAdvisor}
                      items={listAutocomplete}
                      autoFocus
                      onSelect={(e) => {
                        setValue("_payAdvisor", e.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Review Group</label>

                {!editMode ? (
                  <>
                    <input value={employe.reviewGroup} disabled></input>
                  </>
                ) : (
                  <div className="autocomplete-wrapper">
                    <select
                      value={employe.reviewGroup}
                      onChange={(e) => {
                        setValue("reviewGroup", e.target.value);
                      }}
                    >
                      <option>Not assigned</option>
                      <option value="Group 1">Group 1</option>
                      <option value="Group 2">Group 2</option>
                      <option value="Group 3">Group 3</option>
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Executive</label>

                {!editMode ? (
                  <>
                    <input value={employe.executive ? "Yes" : "No"} disabled></input>
                  </>
                ) : (
                  <div className="autocomplete-wrapper">
                    <input
                      type="checkbox"
                      checked={employe.executive}
                      onChange={(e) => {
                        setValue("executive", e.target.checked);
                      }}
                    >
                    </input>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {selectedTab === "history" && (
          <div>
            {/* Financials */}
            <div className="subtitle">History</div>

            {employe.history &&
              employe.history.map((h, i) => (
                <div key={"h_" + i}>
                  <div className="row">
                    <div className="col-2">
                      <div className="label-row">
                        <label>Start Date</label>
                        {i === 0
                          ? employe.startDate && moment(employe.startDate).format("DD MMMM YYYY")
                          : employe.history[i - 1].endDate && moment(employe.history[i - 1].endDate).format("DD MMMM YYYY")}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="label-row">
                        <label>End Date</label>
                        {h.endDate && moment(h.endDate).format("DD MMMM YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-2">
                      <div className="label-row">
                        <label>Office / Division / Department</label>
                        {h.twOffice} / {h.divisionName} / {h.departmentName}
                      </div>

                      <div className="label-row">
                        <label>Payroll Source / Location</label>
                        {h.payrollSource} / {h.location}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="label-row">
                        <label>Team / Position</label>
                        {h.teamName} / {h.position}
                      </div>

                      <div className="label-row">
                        <label>Currency</label>
                        {h.currencyCode}
                      </div>
                    </div>
                  </div>

                  {employe.history.length !== i + 1 && <hr className="mb-30"></hr>}
                </div>
              ))}
            {employe.history && employe.history.length === 0 && <div>No history data</div>}
          </div>
        )}
        {selectedTab === "productFocus" && (
          <div>
            <div className="subtitle">Product Focus</div>

            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>PRO</label>
                  {employe.productFocusPro} %
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>ENT</label>
                  {employe.productFocusEnt} %
                </div>
              </div>
            </div>

          </div>
        )}
        {selectedTab === "budget" && (
          <div>
            <Budget singleView={true} filterInit={{ year: moment().format("YYYY"), hrCode: employe.hrCode }}></Budget>
          </div>
        )}

        {selectedTab === "notes" && (
          <div>
            <div className="note-input">
              <input type="text" placeholder="Add a note..." onChange={(e) => setNote(e.target.value)} value={note}></input>
              <button className="green" onClick={addNote}>
                Add note
              </button>
            </div>
            <div className="note-wrapper">
              {employe.notes &&
                employe.notes.sort(function (a, b) {
                  return new Date(b.date) - new Date(a.date);
                }).map((note, k) => (
                  <div key={k} className="note-row">
                    <div className="date">{moment(note.date).format("DD MMMM YYYY hh:mm A")}</div>
                    <div className="creator">{note.creator}</div>
                    <div className="note">{note.note}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left">
          {checkPermission("editUser") && (
            <>
              {editMode ?
                <button
                  className="green"
                  onClick={() => {
                    save();
                    setEditMode(false);
                  }}
                >
                  Save
                </button> :
                <button className="green" onClick={() => setEditMode(true)}>
                  Edit
                </button>
              }
            </>
          )}
        </div>
        <div className="btn-bar-right">

          {/* <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={save}>
            Update
          </button> */}
          <button className="green" onClick={() => close()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
