import { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.png";

// Context
import { useAuth } from "../context/useAuth";
import { useNavigate } from "react-router-dom";

export default function Submit() {
  let { login } = useAuth();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginFn = async () => {
    if (!(await login(username, password))) {
      setError(true);
    } else {
      navigate("/employee");
    }
  };

  const onEnter = (event) => {
    if (event.key === 'Enter') {
      loginFn();
    }
  }

  return (
    <>
      <Helmet>
        <title>HRB</title>
      </Helmet>

      <div className="login-container">
        <img className="login-logo" src={logo} alt="Logo"></img>
        <div className="login-form">
          <div className="pwd-wrapper">
            <input
              onKeyDown={onEnter}
              onChange={(e) => setUsername(e.target.value)} placeholder="username"></input>
          </div>
          <div className="pwd-wrapper">
            <input
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={onEnter}
              placeholder="password"
              type={showPassword ? "text" : "password"}
            ></input>
            <button className="pwd-btn" onClick={() => setShowPassword(!!!showPassword)}>
              {showPassword ? <i className="material-icons">visibility_off</i> : <i className="material-icons">visibility</i>}
            </button>
          </div>
        </div>
        <button className="big green" onClick={() => loginFn()}>
          Login
        </button>

        {error && <div className="error mt-15">Login not valid</div>}
      </div>
    </>
  );
}
