import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { currencyFormatExcelNum } from "../../../services/utils";
import api from "../../../services/api";

export default async function exportData(filter, teamName, optionExport) {
  const workbook = new ExcelJS.Workbook();

  let getDataApi = api.getBudgetData;

  //   if (filter.groupBy) {
  //     getDataApi = api.getBudgetGroupBy;
  //   }

  let budget = await getDataApi(
    { ...filter, productFocus: true },
    {
      curPage: 1,
      pageSize: 999999,
    }
  );
  budget = budget.data;

  if (optionExport.values === "all") {
    let options = { ...optionExport };

    options.listByMonth = false;
    options.groupByTeam = true;
    options.values = "actual";
    createWorksheet(workbook, budget, options, filter);
    options.groupByTeam = false;

    options.listByMonth = false;
    options.values = "actual";
    createWorksheet(workbook, budget, options, filter);
    options.listByMonth = true;
    createWorksheet(workbook, budget, options, filter);

    options.listByMonth = false;
    options.values = "plan";
    createWorksheet(workbook, budget, options, filter);
    options.listByMonth = true;
    createWorksheet(workbook, budget, options, filter);

    options.listByMonth = false;
    options.values = "percentage";
    createWorksheet(workbook, budget, options, filter);
    options.listByMonth = true;
    createWorksheet(workbook, budget, options, filter);
  } else {
    createWorksheet(workbook, budget, optionExport, filter);
  }

  // Generate Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the Excel file
  let name = `HRB - Export Product Focus ${teamName || "All"} - ${filter.year} - ${optionExport["values"]}.xlsx`;
  saveAs(new Blob([buffer]), name);
}

function createWorksheet(workbook, budget, optionExport, filter) {
  let title = filter.year + " HRB " + optionExport.values + " " + (optionExport.listByMonth ? "detailed" : "consolidated");
  if (optionExport.groupByTeam) {
    title = filter.year + " Consolidated sums by team";
  }
  const worksheet = workbook.addWorksheet(title, { views: [{ showGridLines: false }] });

  worksheet.properties.outlineProperties = {
    summaryBelow: false,
    summaryRight: false,
  };
  let segments = budget.segments;

  let numFmt = "$ #,##0.00";
  let numFmtPerc = "#%";
  const colWidth = 15;
  const colWidthLabel = 15;

  let format = numFmt;
  if (optionExport.values === "percentage") {
    format = numFmtPerc;
  }

  let columns = [
    { header: "Division", key: "Division", width: colWidthLabel },
    { header: "Department", key: "Department", width: colWidthLabel },
    { header: "Team", key: "Team", width: colWidthLabel },
  ];

  if (!optionExport.groupByTeam) {
    columns.push({ header: "EE No.", key: "EE No." });
    columns.push({ header: "First Name", key: "First Name" });
    columns.push({ header: "Last Name", key: "Last Name" });
  }

  if (optionExport.listByMonth) {
    columns.push({ header: "Month", key: "month", width: 7, style: { alignment: { horizontal: "right" } } });
  }

  for (let s of segments) {
    let col = {
      header: s.name,
      key: s.productSegmentId,
      width: colWidth,
    };

    col.style = {
      numFmt: format,
      alignment: { horizontal: "right" },
    };

    columns.push(col);
  }
  columns.push({
    header: "Not Assigned",
    key: "unassigned",
    width: colWidth,
    style: {
      alignment: { horizontal: "right" },
      numFmt: format,
    },
  });

  columns.push({
    header: "Total",
    key: "total",
    width: colWidth,
    style: {
      alignment: { horizontal: "right" },
      numFmt: numFmt,
    },
  });

  worksheet.columns = columns;

  for (let r in budget.rows) {
    let e = budget.rows[r];

    if (optionExport.listByMonth) {
      for (let m = 0; m < 13; m++) {
        let currDate = new Date(filter.year + "-" + (m === 12 ? 12 : m + 1) + "-01 GMT");
        while (e.productFocus[0] && e.productFocus[0].endDate && currDate >= e.productFocus[0].endDate) {
          e.productFocus.shift();
        }

        let currFocus = e.productFocus[0];
        if (!currFocus || currFocus.startDate > currDate) {
          currFocus = { segments: [] };
        }

        addLine(worksheet, e, m, optionExport["values"], currFocus);
      }
    } else {
      // get sum year
      addLine(worksheet, e, 13, optionExport["values"], null, filter.year, segments || []);
    }

    // Border line employee
    // if (optionExport.listByMonth)
    //   for (let c = 0; c < columns.length; c++) {
    //     worksheet.getRow(worksheet.rowCount).getCell(c + 1).border = {
    //       bottom: { style: "thin", color: "5E5E5E" },
    //     };
    //   }
  }

  // Apply styles to the header row
  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "BCC0BF" },
      bgColor: { argb: "BCC0BF" },
    };
    cell.font = {
      bold: true,
      color: { argb: "000" },
    };
  });

  // Calculate total
  if (optionExport.showTotal) {
    let total = { Division: "Total", total: 0 };

    if (optionExport["values"] !== "percentage") {
      total.unassigned = 0;
    }

    for (let c = 2; c <= worksheet.rowCount; c++) {
      let row = worksheet.getRow(c);
      let cell;

      if (optionExport["values"] !== "percentage") {
        for (let s of segments) {
          cell = row.getCell(s.productSegmentId);
          if (!total[s.productSegmentId]) total[s.productSegmentId] = 0;
          total[s.productSegmentId] += cell.value;
        }

        cell = row.getCell("unassigned");
        total["unassigned"] += cell.value;
      }

      cell = row.getCell("total");
      total["total"] += cell.value;
    }

    // if (optionExport["values"] === "percentage") {
    //   let totalSum = 0;
    //   for (let s of segments) {
    //     totalSum += total[s.productSegmentId] || 0;
    //   }
    //   totalSum += total["unassigned"] || 0;

    //   for (let s of segments) {
    //     if (total[s.productSegmentId]) {
    //       total[s.productSegmentId] = totalSum / total[s.productSegmentId];
    //     } else {
    //       total[s.productSegmentId] = 0;
    //     }
    //   }
    //   if (total["unassigned"]) {
    //     total["unassigned"] = totalSum / total["unassigned"];
    //   } else {
    //     total["unassigned"] = 0;
    //   }
    // }
    let totalRow = worksheet.insertRow(1, total);

    for (let c = 0; c < columns.length; c++) {
      totalRow.getCell(c + 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BCC0BF" },
        bgColor: { argb: "BCC0BF" },
      };
      totalRow.font = {
        bold: true,
        color: { argb: "000" },
      };
    }
  }

  if (optionExport.groupByTeam) {
    groupSheetByTeam(worksheet, segments);
  }

  return worksheet;
}

const addLine = (worksheet, e, month, values, currFocus, year, segments) => {
  let employee = {
    Division: e.employee?.divisionName,
    Department: e.employee?.departmentName || e.departmentName,
    Team: e.employee?.teamName || e.teamName,
    "EE No.": e.employee?.hrCode,
    "First Name": e.employee?.firstName,
    "Last Name": e.employee?.lastName,
    month: month + 1,
  };

  let unassigned = 0;
  if (e.months[month]) {
    if (values === "actual") {
      unassigned = e.months[month].totalActual_USD || 0;
    } else if (values === "plan") {
      unassigned = e.months[month].totalPlan_USD || 0;
    }
  }

  if (currFocus) {
    if (values === "percentage") {
      unassigned = 1;
    }
    for (let segment of currFocus.segments) {
      let val = 0;
      if (e.months[month]) {
        val = segment.value / 100;
        if (values === "actual") {
          val = (e.months[month].totalActual_USD / 100) * segment.value;
        } else if (values === "plan") {
          val = (e.months[month].totalPlan_USD / 100) * segment.value;
        }
      }

      if (isNumeric(val) && val !== 0) {
        let remove = parseFloat(val);
        unassigned -= remove;
        unassigned = parseFloat(unassigned);
        employee[segment.productSegmentId] = val;
      }
    }
  } else {
    for (let m = 0; m < 13; m++) {
      let currDate = new Date(year + "-" + (m === 12 ? 12 : m + 1) + "-01 GMT");
      if (e.productFocus && e.productFocus[0]) {
        while (e.productFocus[0] && e.productFocus[0].endDate && currDate > e.productFocus[0].endDate) {
          e.productFocus.shift();
        }

        if (e.productFocus[0].startDate <= currDate) {
          for (let segment of e.productFocus[0].segments) {
            let val = 0;
            if (e.months[m]) {
              val = segment.value / 100 / 13;
              if (values === "actual") {
                val = (e.months[m].totalActual_USD / 100) * segment.value;
              } else if (values === "plan") {
                val = (e.months[m].totalPlan_USD / 100) * segment.value;
              }
            }

            if (isNumeric(val) && val !== 0) {
              let remove = parseFloat(parseFloat(val));
              unassigned -= remove;
              unassigned = parseFloat(parseFloat(unassigned));
              if (!employee[segment.productSegmentId]) employee[segment.productSegmentId] = 0;
              employee[segment.productSegmentId] += val;
            }
          }
        }
      }
    }

    if (values === "percentage") {
      unassigned = 1;
      for (let s of segments) {
        let remove = employee[s.productSegmentId] || 0;
        unassigned -= remove;
      }
      unassigned = parseFloat(parseFloat(unassigned).toFixed(2));
    }
  }

  let total = 0;
  if (e.months[month]) {
    total = e.months[month]["totalActual_USD"];
    if (values === "plan") {
      total = e.months[month]["totalPlan_USD"];
    }
  }

  let row = {
    ...employee,
    total: currencyFormatExcelNum(total),
  };

  if (unassigned && unassigned !== 0) {
    row.unassigned = currencyFormatExcelNum(unassigned);
  }

  worksheet.addRow(row);
};

function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

function groupSheetByTeam(worksheet, segments) {
  let rowsMap = {};

  for (let c = 3; c <= worksheet.rowCount; c++) {
    let row = worksheet.getRow(c);
    let team = row.getCell("Team").value;
    if (!rowsMap[team]) {
      rowsMap[team] = {
        Division: row.getCell("Division").value,
        Department: row.getCell("Department").value,
        Team: row.getCell("Team").value,
        Total: 0,
      };
    }

    for (let s of [...segments, { productSegmentId: "unassigned" }, { productSegmentId: "total" }]) {
      let cell = row.getCell(s.productSegmentId);
      if (!rowsMap[team][s.productSegmentId]) {
        rowsMap[team][s.productSegmentId] = 0;
      }
      rowsMap[team][s.productSegmentId] += cell.value;
    }
  }

  // Clean sheet
  let rowsCount = worksheet.rowCount + 0;
  for (let c = 1; c <= rowsCount; c++) {
    worksheet.spliceRows(3, 1);
  }

  // Add grouped values
  let rows = Object.values(rowsMap);
  rows.sort((a, b) => {
    if (a.Division > b.Division) {
      return 1;
    } else if (a.Division < b.Division) {
      return -1;
    } else {
      if (a.Department > b.Department) {
        return 1;
      } else if (a.Department < b.Department) {
        return -1;
      } else {
        if (a.Team > b.Team) {
          return 1;
        } else if (a.Team < b.Team) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  });

  for (let r of rows) {
    worksheet.addRow(r);
  }

  return worksheet;
}
