import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import api from "../../../services/api";
import moment from "moment";

export default async function exportData(filter, teamName, optionExport) {
  const workbook = new ExcelJS.Workbook();

  let getDataApi = api.getBudgetData;

  let budget = await getDataApi(
    { ...filter, salaryReview: true, month: optionExport.month, reviewGroup: optionExport.reviewGroup },
    {
      curPage: 1,
      pageSize: 999999,
    }
  );
  budget = budget.data;

  if (optionExport.splitBySupervisor) {
    let mapSupervisor = {};
    for (let row of budget.rows) {
      let supervisor = row.employee._paySupervisor;
      if (!mapSupervisor[supervisor]) {
        mapSupervisor[supervisor] = [];
      }
      mapSupervisor[supervisor].push(row);
    }

    let supervisors = await api.getPayrollSupervisors();
    supervisors = supervisors.data;

    for (let supervisor in mapSupervisor) {
      let supervisorObj = supervisors.find((s) => s.hrCode === supervisor);
      let title = supervisorObj ? supervisorObj.lastName + " " + supervisorObj.firstName : supervisor || "Not assigned";
      createWorksheet(workbook, mapSupervisor[supervisor], optionExport, filter, title);
    }
  } else {
    createWorksheet(workbook, budget.rows, optionExport, filter);
  }

  // Generate Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the Excel file
  let name = `HRB - Salary Review - ${moment().month(optionExport.month).format("MMM")} ${filter.year}.xlsx`;
  saveAs(new Blob([buffer]), name);
}

function createWorksheet(workbook, budget, optionExport, filter, title = "Salary Review") {
  const worksheet = workbook.addWorksheet(title, { views: [{ showGridLines: false }] });

  let numFmt = "#,##0.00";
  // let numFmtUSD = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
  let numFmtUSD = "$ #,##0.00";

  const colWidth = 15;
  const colWidthLabel = 25;

  const prevYear = filter.year - 1;

  let columns = [
    { header: "Pay Review\nCycle", key: "Pay Review Cycle" },
    { header: "Division", key: "Division", width: colWidth },
    { header: "Department", key: "Department", width: colWidth },
    { header: "Team", key: "Team", width: colWidth },
    { header: "Position", key: "Position", width: colWidth },
    { header: "HR Code", key: "HR Code" },
    { header: "First Name", key: "First Name" },
    { header: "Last Name", key: "Last Name" },
    // { header: "Currency", key: "Currency" },
    {
      header: "Last Month\nNative",
      key: "lastMonth",
      style: { numFmt: numFmt },
      width: colWidth,
      align: "right",
    },
    { header: "Last Month\nUSD", key: "lastMonth_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },
    { header: "Last Year\nNative ", key: "currentYear", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    { header: "Last Year\nUSD", key: "currentYear_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },
    { header: "Proposed Annual\nNative", key: "proposedAnnual", width: colWidth, align: "right" },
    { header: "Approved Annual\nNative", key: "approvedAnnual", width: colWidth, align: "right" },
    { header: "Start\nMonth", align: "right" },
    { header: "Start\nYear", align: "right" },
    { header: "Pay Supervisor Notes", width: colWidthLabel },
    { header: "Approval Notes", width: colWidthLabel },
    { header: "HR Notes / History", key: "notes", width: colWidthLabel },

    { header: "Dec " + prevYear + "\nNative ", key: "q4", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    { header: "Dec " + prevYear + "\nUSD", key: "q4_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },
    { header: "Sep " + prevYear + "\nNative ", key: "q3", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    { header: "Sep " + prevYear + "\nUSD", key: "q3_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },
    { header: "Jun " + prevYear + "\nNative ", key: "q2", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    { header: "Jun " + prevYear + "\nUSD", key: "q2_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },
    { header: "Mar " + prevYear + "\nNative ", key: "q1", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    { header: "Mar " + prevYear + "\nUSD", key: "q1_USD", style: { numFmt: numFmtUSD }, width: colWidth, align: "right" },

    { header: prevYear + "\nNative ", key: "prevYear", style: { numFmt: numFmt }, width: colWidth, align: "right" },
    {
      header: prevYear + "\nUSD",
      key: "prevYear_USD",
      style: { numFmt: numFmtUSD },
      width: colWidth,
      align: "right",
    },
  ];

  worksheet.columns = columns;

  for (let r in budget) {
    let e = budget[r];
    addLine(worksheet, e);
  }

  // Apply styles to the header row
  const headerRow = worksheet.getRow(1);
  headerRow.height = 30;
  headerRow.eachCell((cell, i) => {
    cell.alignment = { vertical: "middle", wrapText: true };

    if (columns[i - 1]?.align === "right") {
      cell.alignment.horizontal = "right";
    }
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "BCC0BF" },
      bgColor: { argb: "BCC0BF" },
    };
    cell.font = {
      bold: true,
      color: { argb: "000" },
    };
  });

  return worksheet;
}

const addLine = (worksheet, e) => {
  let row = {
    "Pay Review Cycle": e.employee.reviewGroup,
    Division: e.employee.divisionName,
    Department: e.employee.departmentName,
    Team: e.employee.teamName,
    Position: e.employee.position,
    "HR Code": e.employee.hrCode,
    "First Name": e.employee.firstName,
    "Last Name": e.employee.lastName,
    Currency: e.months[0].currencyCode || "USD",
    lastMonth: e.months[0].salaryActual,
    lastMonth_USD: e.months[0].salaryActual_USD,
    currentYear: (e.months[0].salaryActual || 0) * 12,
    currentYear_USD: (e.months[0].salaryActual_USD || 0) * 12,
    q4: e.months[1].salaryActual,
    q4_USD: e.months[1].salaryActual_USD,
    q3: e.months[2].salaryActual,
    q3_USD: e.months[2].salaryActual_USD,
    q2: e.months[3].salaryActual,
    q2_USD: e.months[3].salaryActual_USD,
    q1: e.months[4].salaryActual,
    q1_USD: e.months[4].salaryActual_USD,
    prevYear: (e.months[1].salaryActual || 0) * 12,
    prevYear_USD: (e.months[1].salaryActual_USD || 0) * 12,
    notes: e.employee.notes && e.employee.notes.length > 0 ? e.employee.notes.length + " notes" : "",
  };

  let lastRow = worksheet.addRow(row);
  const numFmtStr =
    '_("' +
    (e.months[0].currencyCode || "USD") +
    '"* #,##0.00_);_("' +
    (e.months[0].currencyCode || "USD") +
    '"* (#,##0.00);_("' +
    (e.months[0].currencyCode || "USD") +
    '"* "-"??_);_(@_)';

  lastRow.getCell("lastMonth").style.numFmt = numFmtStr;
  lastRow.getCell("currentYear").style.numFmt = numFmtStr;
  lastRow.getCell("proposedAnnual").style.numFmt = numFmtStr;
  lastRow.getCell("approvedAnnual").style.numFmt = numFmtStr;
  lastRow.getCell("q4").style.numFmt = numFmtStr;
  lastRow.getCell("q3").style.numFmt = numFmtStr;
  lastRow.getCell("q2").style.numFmt = numFmtStr;
  lastRow.getCell("q1").style.numFmt = numFmtStr;
  lastRow.getCell("prevYear").style.numFmt = numFmtStr;

  if (e.employee.notes && e.employee.notes.length > 0) {
    lastRow.getCell("notes").note = e.employee.notes.reduce(
      (acc, note) => acc + moment(note.date).format("DD MMMM YYYY hh:mm A") + " - " + note.creator + ": " + note.note + "\n",
      ""
    );
  }
};
