import { useEffect, useState } from "react";
import SidebarRight from "./SidebarRight";
import api from "../services/api";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

export default function SidebarFilterBudget({ showSidebar, setShowSidebar, filter, setFilter, setFilterValue }) {
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [twOffices, setTwOffices] = useState([]);
  const [payrollSources, setPayrollSources] = useState([]);
  const [payrollAdvisors, setPayrollAdvisors] = useState([]);
  const [payrollSupervisors, setPayrollSupervisors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [positions, setPositions] = useState([]);
  const currencies = ["USD", "AUD", "GBP", "MXN", "UAH", "EUR", "JPY", "CNY", "CAD"];
  const [filtersDebounce, setFiltersDebounce] = useState(filter);

  useEffect(() => {
    fetchFiltersMap();
    // eslint-disable-next-line
  }, []);

  const setFilterValueDebounceFn = useDebouncedCallback((key, value) => {
    setFilterValue(key, value);
  }, 300);

  const setFilterValueDebounce = (key, value) => {
    let set = {};
    set[key] = value;
    setFiltersDebounce({ ...filter, ...set });
    setFilterValueDebounceFn(key, value);
  };

  const fetchFiltersMap = async () => {
    api.getTeams().then((data) => {
      if (data.status === 200) {
        setTeams(data.data);
      }
    });

    api.getDivisions().then((data) => {
      if (data.status === 200) {
        setDivisions(data.data);
      }
    });

    api.getDepartments().then((data) => {
      if (data.status === 200) {
        setDepartments(data.data);
      }
    });

    api.getTwOffices().then((data) => {
      if (data.status === 200) {
        setTwOffices(data.data);
      }
    });

    api.getPayrollSources().then((data) => {
      if (data.status === 200) {
        setPayrollSources(data.data);
      }
    });

    api.getLocations().then((data) => {
      if (data.status === 200) {
        setLocations(data.data);
      }
    });

    api.getPositions().then((data) => {
      if (data.status === 200) {
        setPositions(data.data);
      }
    });

    api.getPayrollAdvisors().then((data) => {
      if (data.status === 200) {
        setPayrollAdvisors(data.data);
      }
    });

    api.getPayrollSupervisors().then((data) => {
      if (data.status === 200) {
        setPayrollSupervisors(data.data);
      }
    });
  };

  return (
    <SidebarRight show={showSidebar}>
      <div className="sidebar-header">
        <div className="sidebar-title">Filters</div>
        <div className="sidebar-title-right">
          <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
            Close
            <i className="material-icons">chevron_right</i>
          </button>
        </div>
      </div>
      <div className="filter-container">
        <div className="filter-row">
          <select required value={filter.year || ""} onChange={(e) => setFilterValue("year", e.target.value)}>
            <option value="" disabled>
              Year
            </option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
          </select>
        </div>

        {!filter.groupBy && (
          <div className="filter-row">
            <input
              type="text"
              placeholder="First Name"
              value={filtersDebounce.firstName}
              onChange={(e) => setFilterValueDebounce("firstName", e.target.value)}
            />
            <button className="small btn-reset" onClick={() => setFilterValueDebounce("firstName", "")}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}

        {!filter.groupBy && (
          <div className="filter-row">
            <input
              type="text"
              placeholder="Last Name"
              value={filtersDebounce.lastName}
              onChange={(e) => setFilterValueDebounce("lastName", e.target.value)}
            />
            <button className="small btn-reset" onClick={() => setFilterValueDebounce("lastName", "")}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}

        {!filter.groupBy && (
          <div className="filter-row">
            <input
              type="text"
              placeholder="HR Code"
              value={filtersDebounce.hrCode}
              onChange={(e) => setFilterValueDebounce("hrCode", e.target.value)}
            />
            <button className="small btn-reset" onClick={() => setFilterValueDebounce("hrCode", "")}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}

        {!filter.groupBy && (
          <div className="filter-row">
            <input
              type="text"
              placeholder="HR Alias"
              value={filter.hrAlias}
              onChange={(e) => setFilterValue("hrAlias", e.target.value)}
            />
            <button className="small btn-reset" onClick={() => setFilterValue("hrAlias", "")}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}

        <div className="filter-row">
          <select required value={filter.twOffice || ""} onChange={(e) => setFilterValue("twOffice", e.target.value)}>
            <option value="" disabled>
              TW Office
            </option>
            {twOffices.map((t) => (
              <option key={"office_" + t.twOffice} value={t.twOffice}>
                {t.twOffice}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("twOffice", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.departmentName || ""} onChange={(e) => setFilterValue("departmentName", e.target.value)}>
            <option value="" disabled>
              Department
            </option>
            {departments.map((t) => (
              <option key={"div_" + t.departmentName} value={t.departmentName}>
                {t.departmentName}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("departmentName", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.divisionName || ""} onChange={(e) => setFilterValue("divisionName", e.target.value)}>
            <option value="" disabled>
              Division
            </option>
            {divisions.map((t) => (
              <option key={"div_" + t.divisionName} value={t.divisionName}>
                {t.divisionName}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("divisionName", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select
            required
            value={filter.teamId || ""}
            onChange={(e) => {
              setFilterValue("teamId", e.target.value);
              let teamName = e.target.options[e.target.selectedIndex].getAttribute("data-name");
              setFilterValue("teamName", teamName);
            }}
          >
            <option value="" disabled>
              Team
            </option>
            {teams.map((t, t2) => (
              <option key={"team_filter_" + t.teamId + "_" + t2} value={t.teamId} data-name={t.teamName}>
                {t.teamName}
              </option>
            ))}
          </select>
          <button
            className="small btn-reset"
            onClick={() => {
              setFilterValue("teamId", undefined);
              setFilterValue("teamName", undefined);
            }}
          >
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.position || ""} onChange={(e) => setFilterValue("position", e.target.value)}>
            <option value="" disabled>
              Position
            </option>
            {positions.map((t) => (
              <option key={"pos_" + t.position} value={t.position}>
                {t.position}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("position", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.payrollSource || ""} onChange={(e) => setFilterValue("payrollSource", e.target.value)}>
            <option value="" disabled>
              Payroll Source
            </option>
            {payrollSources.map((t) => (
              <option key={"source_" + t.payrollSource} value={t.payrollSource}>
                {t.payrollSource}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("payrollSource", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter._paySupervisor || ""} onChange={(e) => setFilterValue("_paySupervisor", e.target.value)}>
            <option value="" disabled>
              Payroll Supervisor
            </option>
            {payrollSupervisors.map((t) => (
              <option key={"supervisors_" + t.hrCode} value={t.hrCode}>
                {t.firstName} {t.lastName}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("_paySupervisor", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter._payAdvisor || ""} onChange={(e) => setFilterValue("_payAdvisor", e.target.value)}>
            <option value="" disabled>
              Payroll Advisor
            </option>
            {payrollAdvisors.map((t) => (
              <option key={"advisors_" + t.hrCode} value={t.hrCode}>
                {t.firstName} {t.lastName}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("_payAdvisor", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.currencyCode || ""} onChange={(e) => setFilterValue("currencyCode", e.target.value)}>
            <option value="" disabled>
              Currency
            </option>
            {currencies.map((c) => (
              <option key={"curr_" + c} value={c}>
                {c}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("currencyCode", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.location || ""} onChange={(e) => setFilterValue("location", e.target.value)}>
            <option value="" disabled>
              Location
            </option>
            {locations.map((t) => (
              <option key={"loc_" + t.location} value={t.location}>
                {t.location}
              </option>
            ))}
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("location", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.isArchived || ""} onChange={(e) => setFilterValue("isArchived", e.target.value)}>
            <option value="" disabled>
              Active users
            </option>
            <option value={false}>
              Active
            </option>
            <option value={true}>
              Archived
            </option>
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("isArchived", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.groupBy || ""} onChange={(e) => setFilterValue("groupBy", e.target.value)}>
            <option value="" disabled>
              Group By
            </option>
            <option>Do not group</option>
            <option value="Department">Department</option>
            <option value="Team">Team</option>
          </select>

          <button className="small btn-reset" onClick={() => setFilterValue("groupBy", undefined)}>
            <i className="material-icons">close</i>
          </button>
        </div>
      </div>
      <div className="sidebar-footer">
        <button onClick={() => setFilter({ year: moment().format("YYYY") })}>
          <i className="material-icons">close</i>
          Reset
        </button>
      </div>
    </SidebarRight>
  );
}
