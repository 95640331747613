import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Context
import { useAuth } from "./context/useAuth";
import Sidebar from "./components/Sidebar";

// Pages
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import ImportList from "./pages/ImportList";
import EmployeList from "./pages/EmployeList";
import Budget from "./pages/Budget";
import CheckData from "./pages/CheckData";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"main-wrapper" + (auth.user ? " logged" : " hide-sidebar")}>
      <Sidebar></Sidebar>
      <div className="page-container">
        <Routes>
          {auth.user ? (
            <>
              <Route path="/" element={<EmployeList />} />
              <Route path="/employee" element={<EmployeList />} />
              <Route path="/budget/executive" element={<Budget key={"executive"} executive={true} />} />
              <Route path="/budget/teams/:year" element={<Budget key={"groupByTeam"} groupBy={"Team"} executive={false} />} />
              <Route path="/budget/departments/:year" element={<Budget key={"groupByDepartment"} groupBy={"Department"} executive={false} />} />
              <Route path="/budget/department/:departmentName/:year" element={<Budget key={"department"} executive={false} />} />
              <Route path="/budget/:teamId/:teamName/:year" element={<Budget key={"team"} />} executive={false} />
              <Route path="/budget/:year" element={<Budget key={"full"} executive={false} />} />
              <Route path="/check-data" element={<CheckData />} />
              <Route path="/user" element={<UserList />} />
              <Route path="/importHistory" element={<ImportList />} />
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </div>
    </div>
  );
}
