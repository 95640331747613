import { useEffect, useRef, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import Modal from "../components/Modal";
import { useAuth } from "../context/useAuth";
import SidebarRight from "../components/SidebarRight";

export default function CheckData() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalData, setShowModalData] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [filter, setFilter] = useState({});
  const auth = useAuth();
  const tableRef = useRef();

  const deleteRecord = async (error) => {
    await api.deleteBudgetItem(error.budget.id);
  };

  const copyActualsToPlan = async (error) => {
    await api.copyActualsToPlan(error.budget.id);
  };

  const errorsCode = {
    not_hired_actual: {
      description: "Not yet hired in this timeframe",
      fix: deleteRecord,
    },
    not_active_actual: {
      description: "No longer hired in this timeframe",
      fix: deleteRecord,
    },
    not_hired_plan: {
      description: "Not yet hired in this timeframe but has plan values",
      fix: deleteRecord,
    },
    not_active_plan: {
      description: "No longer hired in this timeframe but has plan values",
      fix: deleteRecord,
    },
    wrong_total: {
      description: "Total sum not correct",
      fix: deleteRecord,
    },
    null_values: {
      description: "Some values are null",
    },
    missing_plans: {
      description: "Plan not present with Actual data",
      fix: copyActualsToPlan
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      // Get data
      setLoading(true);
      let data = await api.checkData();
      setList(data.data.errors);
      setLoading(false);
    };

    if (auth.user.id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [auth.user]);

  const setFilterValue = (key, value) => {
    let set = {};
    set[key] = value;
    setFilter({ ...filter, ...set });
  };

  const config = {
    columns: [
      {
        key: "errorCode",
        header: "Error Code",
      },
      {
        header: "HR Code",
        key: "hrCode",
      },
      {
        header: "Description",
        key: "description",
      },
      {
        header: "Budget Date",
        key: "date",
      },
      {
        header: "View Data",
        template: (item) => (
          <div>
            <button className="small" onClick={() => setShowModalData(item)}>
              View
            </button>
          </div>
        ),
      },
      {
        header: "Fix",
        template: (item, k) => (
          <div>
            <button className="small dark" onClick={() => fix(item, k)}>
              Fix
            </button>
          </div>
        ),
      },
    ],
  };

  const fix = async (error) => {
    await errorsCode[error.errorCode].fix(error);
    console.log("remove", error);

    let i = list.findIndex((item) => item.budget.id === error.budget.id);
    list.splice(i, 1);
    setList([...list]);
  };

  const fixAll = async () => {
    let newList = [...tableRef.current.filteredData];
    let length = newList.length;

    for (let i = 0; i < length; i++) {
      await fix(newList[i]);
    }
  };

  return (
    <>
      <div className={"page-wrapper"}>
        <div className="btn-bar">
          <div className="btn-bar-left">
            <button className="green" onClick={() => fixAll()}>
              Fix all
            </button>
          </div>
          <div className="btn-bar-right">
            {!showSidebar && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div className="table-container">
          <Table ref={tableRef} config={config} data={list} filter={filter} loading={loading}></Table>
        </div>
      </div>

      <SidebarRight show={showSidebar}>
        <div className="sidebar-header">
          <div className="sidebar-title">Filters</div>
          <div className="sidebar-title-right">
            <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
              Close
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div className="filter-container">
          <div className="filter-row">
            <input
              type="text"
              placeholder="HR Code"
              value={filter.hrCode}
              onChange={(e) => setFilterValue("hrCode", e.target.value)}
            />

            <button className="small btn-reset" onClick={() => setFilterValue("hrCode", undefined)}>
              <i className="material-icons">close</i>
            </button>
          </div>
          <div className="filter-row">
            <select required value={filter.errorCode || ""} onChange={(e) => setFilterValue("errorCode", e.target.value)}>
              <option value="" disabled>
                Error Code
              </option>
              {Object.keys(errorsCode).map((errorCode) => (
                <option key={"err_" + errorCode} value={errorCode}>
                  {errorCode}
                </option>
              ))}
            </select>
            <button className="small btn-reset" onClick={() => setFilterValue("errorCode", undefined)}>
              <i className="material-icons">close</i>
            </button>
          </div>
        </div>
        <div className="sidebar-footer">
          <button onClick={() => setFilter({ email: "", firstName: "", lastName: "", teamName: "" })}>
            <i className="material-icons">close</i>
            Reset
          </button>
        </div>
      </SidebarRight>

      {showModalData && (
        <Modal close={() => setShowModalData(false)}>
          <div className="modal-header">Data</div>
          <div className="modal-body">
            <h4>Employee</h4>
            <pre>{JSON.stringify(showModalData.employee, null, 2)}</pre>
            <h4>Budget Item</h4>
            <pre>{JSON.stringify(showModalData.budget, null, 2)}</pre>
          </div>
        </Modal>
      )}
    </>
  );
}
