import { useState, useEffect, Fragment } from "react";
import api from "../services/api";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import Modal from "../components/Modal";
import BudgetRow from "../components/BudgetRow";
import SidebarFilterBudget from "../components/SidebarFilterBudget";
import loader from "../images/loader.gif";
import { useAuth } from "../context/useAuth";
import ModalImport from "../components/ModalImport";
import ModalImportResult from "../components/ModalImportResult";
import EmployeEdit from "../components/EmployeEdit";
import ExportModal from "../components/budget/ExportModal";
import moment from "moment";

export default function Budget({ groupBy, filterInit, singleView, executive }) {
  const { departmentName, teamId, teamName, year } = useParams();
  const [teams, setTeams] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [budget, setBudget] = useState([]);
  const [users, setUsers] = useState([]);
  const { saveVisibility, visibility, user, checkPermission } = useAuth();
  const [showModalImport, setShowModalImport] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const [showModalEmployee, setShowModalEmployee] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalCopyMonth, setShowModalCopyMonth] = useState(false);
  const [loadingCopy, setLoadingCopy] = useState(false);

  // Table
  const [loading, setLoading] = useState();
  const [highlightedRow, setHighlightedRow] = useState();
  const [highlightedMonth, setHighlightedMonth] = useState();
  const [highlightedYear, setHighlightedYear] = useState();
  const [highlightedColumn, setHighlightedColumn] = useState();
  const [totPages, setTotPages] = useState(1);
  const [totItems, setTotItems] = useState(0);
  const [pagination, setPagination] = useState({
    curPage: 1,
    pageSize: 20,
  });

  // Mode
  const [editMode, setEditMode] = useState();
  const [copyMode, setCopyMode] = useState();

  // Show
  const [showUSD, setShowUSD] = useState(true);
  const [showPopover, setShowPopover] = useState();
  const [showOther, setShowOther] = useState();
  const [showSidebar, setShowSidebar] = useState(false);

  // Filters
  const [filter, setFilter] = useState(filterInit || { year: year || moment().format("YYYY"), teamId, groupBy, departmentName, executive: executive });

  // Total
  const [totalRow, setTotalRow] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    {},
  ]);

  useEffect(() => {
    fetchBudget();
    // eslint-disable-next-line
  }, [filter, teamId, pagination, filter.groupBy]);

  useEffect(() => {
    fetchUsers();
    api.getTeams().then((data) => {
      if (data.status === 200) {
        setTeams(data.data);
      }
    });

    api.getDepartments().then((data) => {
      if (data.status === 200) {
        setDepartments(data.data);
      }
    });
  }, []);

  useEffect(() => {
    filter.teamId = teamId;
    setFilter(filter);
    // eslint-disable-next-line
  }, [teamId]);

  const fetchUsers = async () => {
    let res = await api.getUsersNames();
    setUsers(res.data);
  };

  const setFilterValue = (key, value) => {
    setFilter((filter) => {
      let set = {};
      set[key] = value;
      return { ...filter, ...set };
    });
  };

  const setPaginationValue = (key, value) => {
    let set = {};
    set[key] = value;
    setPagination({ ...pagination, ...set });
  };

  const prevPage = () => {
    goToPage(pagination.curPage - 1);
  };

  const nextPage = () => {
    goToPage(pagination.curPage + 1);
  };

  const goToPage = (page) => {
    setPaginationValue("curPage", page);
  };

  const setPageSize = (pageSize) => {
    setPagination({
      curPage: 1,
      pageSize,
    });
  };

  const fetchBudget = async (ignoreLoading) => {
    if (!ignoreLoading) setLoading(true);

    let getDataApi = api.getBudgetData;

    if (filter.groupBy) {
      getDataApi = api.getBudgetGroupBy;
    }

    let budget = await getDataApi(filter, pagination);

    setLoading(false);
    if (budget.status === 200) {
      setBudget(budget.data.rows);
      let total = getTotal(budget.data.rows);
      setTotalRow(total);

      let totPages = Math.ceil(budget.data.count / pagination.pageSize);
      setTotItems(budget.data.count);
      setTotPages(totPages || 1);
    } else {
      alert("Error getting data");
    }
  };

  const getTotal = (budget) => {
    let total = [];
    for (let employee of budget) {
      for (let month in employee.months) {
        if (employee.months[month]) {
          if (!total[month]) {
            total[month] = {
              bonusActual: 0,
              bonusActual_USD: 0,
              bonusPlan: 0,
              bonusPlan_USD: 0,
              currencyCode: 0,
              otherActual: 0,
              otherActualMap: {},
              otherActualMap_USD: {},
              otherActual_USD: 0,
              otherPlan: 0,
              otherPlan_USD: 0,
              salaryActual: 0,
              salaryActual_USD: 0,
              salaryPlan: 0,
              salaryPlan_USD: 0,
              totalActual: 0,
              totalActual_USD: 0,
              totalPlan: 0,
              totalPlan_USD: 0,
            };
          }

          if (month === 13) {
            total[month].bonusActual += employee.months[month].bonusActual;
            total[month].bonusPlan += employee.months[month].bonusPlan;
            total[month].otherActual += employee.months[month].otherActual;
            total[month].otherPlan += employee.months[month].otherPlan;
            total[month].salaryActual += employee.months[month].salaryActual;
            total[month].salaryPlan += employee.months[month].salaryPlan;
            total[month].totalActual += employee.months[month].totalActual;
            total[month].totalPlan += employee.months[month].totalPlan;
          } else {
            total[month].bonusActual_USD += employee.months[month].bonusActual_USD || 0;
            total[month].bonusPlan_USD += employee.months[month].bonusPlan_USD || 0;
            total[month].otherActual_USD += employee.months[month].otherActual_USD || 0;
            total[month].otherPlan_USD += employee.months[month].otherPlan_USD || 0;
            total[month].salaryActual_USD += employee.months[month].salaryActual_USD || 0;
            total[month].salaryPlan_USD += employee.months[month].salaryPlan_USD || 0;
            total[month].totalActual_USD += employee.months[month].totalActual_USD || 0;
            total[month].totalPlan_USD += employee.months[month].totalPlan_USD || 0;

            total[month].bonusActual = total[month].bonusActual_USD;
            total[month].bonusPlan = total[month].bonusPlan_USD;
            total[month].currencyCode = total[month].currencyCode_USD;
            total[month].otherActual = total[month].otherActual_USD;
            total[month].otherPlan = total[month].otherPlan_USD;
            total[month].salaryActual = total[month].salaryActual_USD;
            total[month].salaryPlan = total[month].salaryPlan_USD;
            total[month].totalActual = total[month].totalActual_USD;
            total[month].totalPlan = total[month].totalPlan_USD;
          }
        }
      }
    }

    if (!total[13]) total[13] = {};
    return total;
  };

  const highlightCell = async (rowId, month) => {
    if (copyMode) return;

    setHighlightedMonth();

    if (highlightedRow === rowId && highlightedColumn === month) {
      setHighlightedRow(null);
      setHighlightedColumn(null);
    } else {
      setHighlightedRow(rowId);
      setHighlightedColumn(month);
    }
  };

  const changeYear = async (year) => {
    setHighlightedRow(null);
    setHighlightedColumn(null);
    setPaginationValue("curPage", 1);
    setFilterValue("year", year);
  };

  const toggleVisibility = async (type) => {
    visibility[type] = !!!visibility[type];
    saveVisibility(visibility);
  };

  const changeValue = async (value, employeeId, indexEmployee, month, attr) => {
    if (!budget[indexEmployee].months[month]) budget[indexEmployee].months[month] = {};
    if (attr !== "otherActualMap" && attr !== "otherActualMap_USD") {
      value = parseFloat(value) || 0;
    }
    budget[indexEmployee].months[month][attr] = value;
    setBudget([...budget]);
    let total = getTotal([...budget]);
    setTotalRow(total);

    saveData(value, employeeId, indexEmployee, teamId, teamName, month, filter.year, attr);
  };

  const saveData = useDebouncedCallback(async (value, employeeId, indexEmployee, teamId, teamName, month, year, attr) => {
    let newBudget = await api.editBudgetData(value, employeeId, teamName, attr, month + 1, filter, pagination);
    if (newBudget.ok) {
      setBudget([...newBudget.data.rows]);
      let total = getTotal([...newBudget.data.rows]);
      setTotalRow(total);
    } else {
      alert("Error updating data");
    }
  }, 300);

  const openModalOther = (indexEmployee, month, fieldNameCurrency) => {
    let fieldMap = "otherActualMap";
    if (fieldNameCurrency.endsWith("_USD")) {
      fieldMap = "otherActualMap_USD";
    }
    setShowOther({ indexEmployee, month, fieldMap });
  };

  const setOtherValue = (otherField, field, value) => {
    budget[showOther.indexEmployee].months[showOther.month][otherField][field] = parseFloat(value) || 0;
    let employeeId = budget[showOther.indexEmployee].id;
    changeValue(
      budget[showOther.indexEmployee].months[showOther.month][otherField],
      employeeId,
      showOther.indexEmployee,
      showOther.month,
      otherField
    );
  };

  const toggleMonthSelected = async (month) => {
    if (!copyMode) {
      // Toggle month
      if (highlightedMonth === month) {
        setHighlightedMonth();
      } else {
        setHighlightedMonth(month);
        setHighlightedYear(filter.year);
      }
      setHighlightedRow(null);
      setHighlightedColumn(null);
    } else {
      // Paste values
      let saveInfo = [];
      for (let e of copyMode) {
        if (budget[e.index]) {
          if (!budget[e.index].months[month]) budget[e.index].months[month] = {};
          budget[e.index].months[month].salaryPlan = e.salaryPlan;
          budget[e.index].months[month].bonusPlan = e.bonusPlan;
          budget[e.index].months[month].otherPlan = e.otherPlan;
        }
        saveInfo.push({
          employeeId: e.id,
          salaryPlan: e.salaryPlan,
          bonusPlan: e.bonusPlan,
          otherPlan: e.otherPlan,
        });
      }

      // Save data
      setBudget([...budget]);
      let newBudget = await api.editBudgetDataMonth(saveInfo, teamName, month + 1, filter, pagination);
      if (newBudget.ok) {
        setBudget([...newBudget.data.rows]);
        let total = getTotal([...newBudget.data.rows]);
        setTotalRow(total);
      } else {
        alert("Error updating data");
      }
    }
  };

  const toggleCopyMode = () => {
    if (copyMode) {
      setCopyMode(null);
    } else {
      let copiedValues = [];

      for (let e in budget) {
        let employee = budget[e];
        copiedValues.push({
          index: parseInt(e),
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          position: employee.position,
          salaryPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].salaryPlan : 0,
          bonusPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].bonusPlan : 0,
          otherPlan: employee.months[highlightedMonth] ? employee.months[highlightedMonth].otherPlan : 0,
        });
      }
      setCopyMode(copiedValues);
    }
  };

  const printTotal = () => {
    const params = {
      employee: {
        lastName: "TOTAL in USD",
        months: totalRow,
      },
      users,
      indexRow: -1,
      visibility,
      editMode,
      highlightedRow,
      highlightedColumn,
      highlightedMonth,
      highlightedYear,
      currentYear: filter.year,
      highlightCell,
      changeValue,
      showUSD,
      editable: false,
      selectable: false,
    };

    return (
      <>
        <tr className="name-row total-sum-row">
          <td colSpan={15}>
            <span className="name-value">TOTAL</span>
          </td>
        </tr>

        {/* PLAN */}
        <BudgetRow field="salary" type="plan" title="Base" {...params} customClass="plan-row total-sum-row"></BudgetRow>
        <BudgetRow field="bonus" type="plan" title="Bonus" {...params} customClass="plan-row total-sum-row"></BudgetRow>
        <BudgetRow field="other" type="plan" title="Others" {...params} customClass="plan-row total-sum-row"></BudgetRow>
        <BudgetRow field="total" type="plan" title="Loaded" {...params} customClass="total-row total-sum-row"></BudgetRow>
        {/* Actual */}

        <BudgetRow field="salary" type="actual" title="Base" {...params} customClass="actual-row total-sum-row"></BudgetRow>
        <BudgetRow field="bonus" type="actual" title="Bonus" {...params} customClass="actual-row total-sum-row"></BudgetRow>
        <BudgetRow field="other" type="actual" title="Others" {...params} customClass="actual-row total-sum-row"></BudgetRow>
        <BudgetRow field="total" type="actual" title="Loaded" {...params} customClass="total-row total-sum-row"></BudgetRow>
      </>
    );
  };

  const closeModalImport = (importResult) => {
    setShowModalImport(false);
    if (importResult) {
      fetchBudget();
      setShowModalResult(importResult.length > 1 ? undefined : importResult[0].result);
    }
  };

  const openEmployeeModal = async (e) => {
    let employee = await api.getEmploye(e.id);
    setShowModalEmployee(employee.data);
  };

  const askCopyMonth = (params) => {
    setShowModalCopyMonth(params);
  };

  const copyMonth = async () => {
    setLoadingCopy(true);
    for (let m = showModalCopyMonth.m + 2; m <= 13; m++) {
      await api.editBudgetData(
        showModalCopyMonth.value,
        showModalCopyMonth.employeeId,
        null,
        showModalCopyMonth.fieldNameCurrency,
        m,
        filter
      );
    }
    setLoadingCopy(false);

    setShowModalCopyMonth(false);
    fetchBudget(true);
  };

  const prevGroup = () => {
    let index = 0;
    let groups = filter.teamName ? teams : departments;
    let key = filter.teamName ? "teamId" : "departmentName";
    let val = filter[key];
    for (let i in groups) {
      if (groups[i][key] === val) {
        index = parseInt(i) - 1;
        break;
      }
    }

    if (index === -1) {
      index = groups.length - 1;
    }

    setFilterValue(key, groups[index][key]);

    if (filter.teamName) {
      setFilterValue("teamName", groups[index].teamName);
    }
  };

  const nextGroup = () => {
    let index = 0;
    let groups = filter.teamName ? teams : departments;
    let key = filter.teamName ? "teamId" : "departmentName";
    let val = filter[key];
    for (let i in groups) {
      if (groups[i][key] === val) {
        index = parseInt(i) + 1;
        break;
      }
    }

    if (index >= groups.length) {
      index = 0;
    }

    setFilterValue(key, groups[index][key]);

    if (filter.teamName) {
      setFilterValue("teamName", groups[index].teamName);
    }
  };

  return (
    <>
      <div className={"page-wrapper budget-edit-container" + (singleView ? " single-view" : "")}>
        <div className="btn-bar">
          <div className="btn-bar-left">
            {!filter.groupBy && user && (checkPermission("editPlan")) && (
              <button className={editMode ? "green" : "link"} onClick={() => setEditMode(!!!editMode)}>
                Edit Plan
              </button>
            )}

            {!filter.groupBy && (
              <button className={!showUSD ? "green" : "link"} onClick={() => setShowUSD(!!!showUSD)}>
                {showUSD ? "Show Original Currency" : "Show USD"}
              </button>
            )}

            {!singleView && (
              <>
                {((!filter.groupBy && highlightedMonth !== undefined) || copyMode) && (
                  <button className={copyMode ? "green" : "link"} onClick={() => toggleCopyMode()}>
                    {copyMode ? "Quit copy mode" : "Copy values"}
                  </button>
                )}

                {checkPermission("importActuals") && (
                  <button className="link" onClick={() => setShowModalImport(true)}>
                    Import CSV
                  </button>
                )}

                {checkPermission("exports") && (
                  <button className={"link"} onClick={() => setShowModalExport(true)}>
                    Export
                  </button>)}

                {checkPermission("checkData") && (
                  <NavLink to="/check-data">
                    <button className="link">Check Data</button>
                  </NavLink>
                )}

                {/* {highlightedMonth === undefined ? (
                  <button className={"link"} onClick={() => exportAll()}>
                    Export
                  </button>
                ) : (
                  <button className={"link"} onClick={() => exportMonth()}>
                    Export Month
                  </button>
                )}

                <button className={"link"} onClick={() => setShowModalExportComparison(true)}>
                  Export Comparison
                </button> */}
              </>
            )}
          </div>
          <div className="btn-bar-right">
            {!showSidebar && !singleView && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div
          className={"table-container" + (copyMode ? " copy-mode" : "") + (editMode ? " edit-mode" : "")}
          style={{ overflow: "hidden" }}
        >
          <div className="btn-bar">
            <div className="btn-bar-left btn-bar-arrows">
              {(filter.teamName || filter.departmentName) && (
                <>
                  <button className="link" onClick={() => prevGroup()}>
                    <i className="material-icons">chevron_left</i>
                  </button>
                  <h3>{filter.teamName || filter.departmentName}</h3>
                  <button className="link" onClick={() => nextGroup()}>
                    <i className="material-icons">chevron_right</i>
                  </button>
                </>
              )}
            </div>
            <div className="btn-bar-right">
              <button className="link" onClick={() => changeYear(parseInt(filter.year) - 1)}>
                <i className="material-icons">chevron_left</i>
              </button>
              <div className="year-filter"> {filter.year}</div>
              <button className="link" onClick={() => changeYear(parseInt(filter.year) + 1)}>
                <i className="material-icons">chevron_right</i>
              </button>
            </div>
          </div>
          <div className="table-container top-header">
            {showPopover && (
              <div className="popover">
                <div className="popover-row" onClick={() => toggleVisibility("salaryPlan")}>
                  <input type="checkbox" checked={visibility.salaryPlan}></input>
                  Plan Salary
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("bonusPlan")}>
                  <input type="checkbox" checked={visibility.bonusPlan}></input>
                  Plan bonus
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("otherPlan")}>
                  <input type="checkbox" checked={visibility.otherPlan}></input>
                  Plan other
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("totalPlan")}>
                  <input type="checkbox" checked={visibility.totalPlan}></input>
                  Plan total
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("salaryActual")}>
                  <input type="checkbox" checked={visibility.salaryActual}></input>
                  Actual Salary
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("bonusActual")}>
                  <input type="checkbox" checked={visibility.bonusActual}></input>
                  Actual bonus
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("otherActual")}>
                  <input type="checkbox" checked={visibility.otherActual}></input>
                  Actual other
                </div>
                <div className="popover-row" onClick={() => toggleVisibility("totalActual")}>
                  <input type="checkbox" checked={visibility.totalActual}></input>
                  Actual total
                </div>
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th className="left">
                    <i className="material-icons" onClick={() => setShowPopover(!!!showPopover)}>
                      more_horizontal
                    </i>
                    <span>Name</span>
                  </th>
                  <th
                    className={
                      highlightedColumn === 0 || (highlightedMonth === 0 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(0)}
                  >
                    Jan
                  </th>
                  <th
                    className={
                      highlightedColumn === 1 || (highlightedMonth === 1 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(1)}
                  >
                    Feb
                  </th>
                  <th
                    className={
                      highlightedColumn === 2 || (highlightedMonth === 2 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(2)}
                  >
                    Mar
                  </th>
                  <th
                    className={
                      highlightedColumn === 3 || (highlightedMonth === 3 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(3)}
                  >
                    Apr
                  </th>
                  <th
                    className={
                      highlightedColumn === 4 || (highlightedMonth === 4 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(4)}
                  >
                    May
                  </th>
                  <th
                    className={
                      highlightedColumn === 5 || (highlightedMonth === 5 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(5)}
                  >
                    Jun
                  </th>
                  <th
                    className={
                      highlightedColumn === 6 || (highlightedMonth === 6 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(6)}
                  >
                    Jul
                  </th>
                  <th
                    className={
                      highlightedColumn === 7 || (highlightedMonth === 7 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(7)}
                  >
                    Aug
                  </th>
                  <th
                    className={
                      highlightedColumn === 8 || (highlightedMonth === 8 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(8)}
                  >
                    Sep
                  </th>
                  <th
                    className={
                      highlightedColumn === 9 || (highlightedMonth === 9 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(9)}
                  >
                    Oct
                  </th>
                  <th
                    className={
                      highlightedColumn === 10 || (highlightedMonth === 10 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(10)}
                  >
                    Nov
                  </th>
                  <th
                    className={
                      highlightedColumn === 11 || (highlightedMonth === 11 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(11)}
                  >
                    Dec
                  </th>
                  <th
                    className={
                      highlightedColumn === 12 || (highlightedMonth === 12 && highlightedYear === filter.year) ? "selected" : ""
                    }
                    onClick={() => toggleMonthSelected(12)}
                  >
                    13th
                  </th>
                  <th
                    className={
                      highlightedColumn === 13 || (highlightedMonth === 13 && highlightedYear === filter.year) ? "selected" : ""
                    }
                  >
                    <b>Total</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="loader-container">
                    <td colSpan={15}>
                      <div className="loader">
                        <img alt="loader" src={loader}></img>
                        <div>Loading ...</div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {/* TOTAL ROW */}

                    {!singleView && printTotal()}

                    {budget.map((employee, indexRow) => {
                      const params = {
                        employee,
                        indexRow,
                        visibility,
                        editMode,
                        highlightedRow,
                        highlightedColumn,
                        highlightedMonth,
                        highlightedYear,
                        currentYear: filter.year,
                        highlightCell,
                        changeValue,
                        showUSD,
                        askCopyMonth,
                        users,
                      };

                      return (
                        <Fragment key={"row_" + indexRow}>
                          {!singleView && (
                            <tr className="name-row">
                              <td colSpan={15}>
                                {filter.groupBy ? (
                                  <>
                                    <span className="name-value">
                                      {filter.groupBy === "Team" && (
                                        <Link
                                          to={`/budget/${employee.id}/${encodeURIComponent(employee.teamName)}/${filter.year}`}
                                        >
                                          {employee.teamName}
                                        </Link>
                                      )}
                                      {filter.groupBy === "Department" && (
                                        <Link
                                          to={`/budget/department/${encodeURIComponent(employee.departmentName)}/${filter.year}`}
                                        >
                                          {employee.departmentName || "Unknown Department"}
                                        </Link>
                                      )}
                                    </span>
                                    <span className="role-value">
                                      {" "}
                                      {employee.countEmployees} employee{employee.countEmployees > 1 && "s"}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="name-value" onClick={() => openEmployeeModal(employee)}>
                                      {employee.lastName}, {employee.firstName}
                                    </span>
                                    <span className="role-value">
                                      {employee.employee && employee.employee.teamName} - {employee.position}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          )}

                          {/* PLAN */}
                          <BudgetRow field="salary" type="plan" title="Base" selectable={true} {...params}></BudgetRow>
                          <BudgetRow field="bonus" type="plan" title="Bonus" selectable={true} {...params}></BudgetRow>
                          <BudgetRow field="other" type="plan" title="Others" selectable={true} {...params}></BudgetRow>
                          <BudgetRow
                            field="total"
                            type="plan"
                            title="Loaded"
                            editable={false}
                            selectable={true}
                            customClass="total-row"
                            {...params}
                          ></BudgetRow>
                          {/* Actual */}

                          <BudgetRow field="salary" type="actual" editable={false} title="Base" {...params}></BudgetRow>
                          <BudgetRow field="bonus" type="actual" editable={false} title="Bonus" {...params}></BudgetRow>
                          <BudgetRow
                            field="other"
                            type="actual"
                            title="Others"
                            editable={false}
                            openModalOther={openModalOther}
                            {...params}
                          ></BudgetRow>
                          <BudgetRow
                            field="total"
                            type="actual"
                            title="Loaded"
                            editable={false}
                            customClass="total-row"
                            {...params}
                          ></BudgetRow>
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </tbody>
              {!singleView && (
                <tfoot>
                  <tr>
                    <td colSpan={15}>
                      <div className="table-footer">
                        <div className="pagination-list">
                          {pagination.curPage > 1 && (
                            <button className="link arrow" onClick={prevPage}>
                              <i className="material-icons">chevron_left</i>
                            </button>
                          )}
                          {Array.from(Array(pagination.curPage > 3 ? 3 : pagination.curPage - 1).keys()).map((page) => (
                            <button key={"page_" + (page + 1)} className="link" onClick={() => goToPage(page + 1)}>
                              {page + 1}
                            </button>
                          ))}
                          <button className="link disabled" disabled={true}>
                            {pagination.curPage}
                          </button>
                          {Array.from(Array(totPages - pagination.curPage > 3 ? 3 : totPages - pagination.curPage).keys()).map(
                            (page) => (
                              <button
                                key={"page_" + (page + 1 + pagination.curPage)}
                                className="link"
                                onClick={() => goToPage(page + 1 + pagination.curPage)}
                              >
                                {page + 1 + pagination.curPage}
                              </button>
                            )
                          )}
                          {pagination.curPage !== totPages && (
                            <button className="link arrow" onClick={nextPage}>
                              <i className="material-icons">chevron_right</i>
                            </button>
                          )}
                        </div>

                        <div className="page-control">
                          <div className="page-counter">
                            Page {pagination.curPage}/{totPages}
                          </div>
                          <div className="page-size">
                            Page Size
                            <select value={pagination.pageSize} onChange={(e) => setPageSize(e.target.value)}>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                              <option value={500}>500</option>
                              <option value={1000}>1000</option>
                            </select>
                          </div>
                          <div className="page-items">{totItems} Items</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>

            {showOther && (
              <Modal size={"small"} close={() => setShowOther(false)}>
                <div className="modal-header">Edit Other cost</div>

                <div className="modal-body">
                  <div className="subtitle">Other costs</div>

                  {Object.keys(budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap]).map((field) => (
                    <div className="label-row" key={"other_" + field}>
                      <label>
                        {field}{" "}
                        {!showOther.fieldMap.endsWith("_USD") &&
                          "(" + budget[showOther.indexEmployee].months[showOther.month].currencyCode + ")"}
                      </label>
                      <span className="align-right">
                        <input
                          readOnly={true}
                          value={budget[showOther.indexEmployee].months[showOther.month][showOther.fieldMap][field]}
                          onChange={(e) => setOtherValue(showOther.fieldMap, field, e.target.value)}
                        ></input>
                      </span>
                    </div>
                  ))}
                </div>

                <div className="modal-footer">
                  <div className="btn-bar-left"></div>
                  <div className="btn-bar-right">
                    <button className="green" onClick={() => setShowOther(false)}>
                      Done
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>

      <ExportModal
        highlightedMonth={highlightedMonth}
        visibility={visibility}
        show={showModalExport}
        close={setShowModalExport}
        budget={budget}
        filter={filter}
        teamName={teamName}
      ></ExportModal>

      {showModalCopyMonth && (
        <Modal close={() => setShowModalCopyMonth(false)} size={"small"}>
          <div className="modal-header">Copy Month</div>

          <div className="modal-body">Do you want to copy the value of this month for the rest of the year?</div>

          <div className="modal-footer">
            <div className="btn-bar-left"></div>
            <div className="btn-bar-right">
              <button className="" onClick={() => setShowModalCopyMonth(false)}>
                Cancel
              </button>
              <button className="green" disabled={loadingCopy} onClick={() => copyMonth()}>
                {loadingCopy ? "Loading..." : "Copy"}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showModalImport && (
        <Modal close={() => setShowModalImport(false)} size={"small"}>
          <ModalImport close={closeModalImport}></ModalImport>
        </Modal>
      )}

      {showModalResult && (
        <ModalImportResult showModalResult={showModalResult} setShowModalResult={setShowModalResult}></ModalImportResult>
      )}

      {showModalEmployee && (
        <Modal close={() => setShowModalEmployee(false)}>
          <EmployeEdit employee={showModalEmployee} close={() => setShowModalEmployee(false)}></EmployeEdit>
        </Modal>
      )}

      {!singleView && (
        <SidebarFilterBudget
          filter={filter}
          setFilter={setFilter}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          setFilterValue={setFilterValue}
        ></SidebarFilterBudget>
      )}
    </>
  );
}
