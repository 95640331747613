import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  // Security
  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const validateToken = () => {
    return api.get(`/api/users/validateToken`, {});
  };

  // User
  const getUsers = () => {
    return api.get(`/api/users`);
  };

  const getUsersNames = () => {
    return api.get(`/api/users/names`);
  };

  const saveUser = (user) => {
    return api.post(`/api/users/${user.id}`, user);
  };

  const createUser = (user) => {
    return api.post(`/api/users`, user);
  };

  const saveVisibility = (visibility) => {
    return api.post(`/api/users/visibility`, visibility);
  };

  const setPassword = (id, password) => {
    return api.post(`/api/users/${id}/setPassword`, { password });
  };

  // Employe
  const getEmploye = (id) => {
    return api.get(`/api/employees/${id}`);
  };

  const getEmployees = () => {
    return api.get(`/api/employees`);
  };

  const importEmployees = (data) => {
    return api.post(`/api/employees/import`, data);
  };

  const removeEmploye = (id) => {
    return api.delete(`/api/employees/${id}`);
  };

  const saveEmploye = (employe) => {
    return api.post(`/api/employees/${employe.id}`, employe);
  };

  const getTeams = () => {
    return api.get(`/api/employees/teams`);
  };

  const getDivisions = () => {
    return api.get(`/api/employees/divisions`);
  };

  const getDepartments = () => {
    return api.get(`/api/employees/departments`);
  };

  const getLocations = () => {
    return api.get(`/api/employees/locations`);
  };

  const getPositions = () => {
    return api.get(`/api/employees/positions`);
  };

  const getTwOffices = () => {
    return api.get(`/api/employees/twOffices`);
  };

  const getPayrollSources = () => {
    return api.get(`/api/employees/payrollSources`);
  };

  const getPayrollSupervisors = () => {
    return api.get(`/api/employees/payrollSupervisors`);
  };

  const getPayrollAdvisors = () => {
    return api.get(`/api/employees/payrollAdvisors`);
  };

  const syncBos = () => {
    return api.get(`/api/employees/syncBos`);
  };

  const addNote = (employeeId, note) => {
    return api.post(`/api/employees/${employeeId}/addNote`, { note });
  };

  // Budget
  const getBudgetData = (filter, pagination) => {
    return api.post(`/api/budgets/getBudget`, { filter, pagination });
  };

  const getBudgetGroupBy = (filter, pagination) => {
    return api.post(`/api/budgets/getBudgetGroupBy`, { filter, pagination });
  };

  const editBudgetData = (value, employeeId, teamName, attr, month, filters, pagination) => {
    return api.post(`/api/budgets/updateData`, {
      value,
      employeeId,
      teamName,
      attr,
      month,
      filters,
      pagination,
    });
  };

  const editBudgetDataMonth = (employees, teamName, month, filters, pagination) => {
    return api.post(`/api/budgets/updateDataMonth`, {
      employees,
      teamName,
      month,
      filters,
      pagination,
    });
  };

  const importBudget = (data, month, year, currency) => {
    return api.post(`/api/budgets/import`, { data, month, year, currency });
  };

  const importReview = (data) => {
    return api.post(`/api/budgets/importReview`, { data });
  };

  const getImports = () => {
    return api.get(`/api/budgets/imports`);
  };

  const checkData = () => {
    return api.get(`/api/budgets/checkData`);
  };

  const deleteBudgetItem = (id) => {
    return api.delete(`/api/budgets/item/${id}`);
  };

  const copyActualsToPlan = (id) => {
    return api.post(`/api/budgets/item/${id}/copyActualsToPlan`);
  };

  // return functions for interface
  return {
    setToken,
    login,

    // User
    validateToken,
    getUsers,
    getUsersNames,
    saveUser,
    createUser,
    saveVisibility,
    setPassword,

    // Employe
    getEmploye,
    getEmployees,
    importEmployees,
    removeEmploye,
    saveEmploye,
    getTeams,
    getDivisions,
    getDepartments,
    getLocations,
    getPositions,
    getTwOffices,
    getPayrollSources,
    getPayrollSupervisors,
    getPayrollAdvisors,
    syncBos,
    addNote,

    // Budget
    getBudgetData,
    getBudgetGroupBy,
    editBudgetData,
    editBudgetDataMonth,
    importBudget,
    importReview,
    getImports,

    // Fix Data
    checkData,
    deleteBudgetItem,
    copyActualsToPlan
  };
};

// Creates a singleton
export default createApi();
