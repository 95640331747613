import { useEffect, useState } from "react";
import api from "../services/api";
import Modal from "./Modal";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default function UserEdit({ userInit, index, list, setList, close }) {
  const [user, setUser] = useState({ ...userInit });
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [showModalChangePwd, setShowModalChangePwd] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [listAutocomplete, setListAutocomplete] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let data = await api.getEmployees();
      setListAutocomplete(
        data.data.map((e) => {
          return { value: e.hrCode, name: e.hrCode + " - " + e.firstName + " " + e.lastName };
        })
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      api.getTeams().then((data) => {
        if (data.status === 200) {
          setTeams(data.data);
        }
      });
    };

    fetchTeams();
  }, []);

  const setValue = (key, value) => {
    user[key] = value;
    setUser({ ...user });
  };

  const changePassword = async () => {
    if (!newPassword) {
      return alert("Insert a valid password");
    }
    let data = await api.setPassword(user.id, newPassword);

    if (data.status === 200) {
      alert("Password changed");
      setShowModalChangePwd(false);
    }
  };

  const save = async () => {
    if (!user.email) {
      return alert("Insert a valid email");
    }
    if (!user.id && !user.password) {
      return alert("Insert a valid password");
    }

    if (!user.role) {
      return alert("Select a role");
    }

    if (user.id) {
      let data = await api.saveUser(user);

      if (data.status === 200) {
        list[index] = data.data;
        setList([...list]);
        close();
      }
    } else {
      let data = await api.createUser(user);

      if (data.status === 200) {
        list.push(data.data);
        setList([...list]);
        close();
      }
    }
  };

  const addTeam = () => {
    if (!user.teams) {
      user.teams = [];
    }

    user.teams.push(selectedTeam);
    setSelectedTeam("");
  };

  const removeTeam = (i) => {
    user.teams.splice(i, 1);
    setUser({ ...user });
  };

  return (
    <div>
      <div className="modal-header">{user.id ? "Edit User" : "Create User"}</div>

      <div className="modal-body">
        <div className="subtitle">User Info</div>
        <div className="label-row">
          <label>Email</label>
          <input value={user.email} onChange={(e) => setValue("email", e.target.value)}></input>
        </div>
        {!user.id && (
          <div className="label-row">
            <label>Password</label>
            <input value={user.password} type="password" onChange={(e) => setValue("password", e.target.value)}></input>
          </div>
        )}
        <div className="label-row">
          <label>First Name</label>
          <input value={user.firstName} onChange={(e) => setValue("firstName", e.target.value)}></input>
        </div>
        <div className="label-row">
          <label>Last Name</label>
          <input value={user.lastName} onChange={(e) => setValue("lastName", e.target.value)}></input>
        </div>
        <div className="label-row">
          <label>Role</label>
          <select value={user.role || ""} onChange={(e) => setValue("role", e.target.value)}>
            <option value="" disabled>
              Select a role
            </option>
            <option value="admin">Admin</option>
            <option value="executive">Executive</option>
            <option value="finance">Finance</option>
            <option value="supervisor">Supervisor</option>
            <option value="payAdvisor">Pay Advisor</option>
            <option value="hr">HR</option>
          </select>
        </div>

        {user.role === "manager" && (
          <div>
            <h5>Teams permissions</h5>
            <div className="label-row">
              <label>Add a team</label>
              <select required value={selectedTeam || ""} defaultValue={""} onChange={(e) => setSelectedTeam(e.target.value)}>
                <option value="" disabled>
                  Team
                </option>
                {teams
                  .filter((t) => !user.teams || user.teams.indexOf(t.teamId) === -1)
                  .map((t) => (
                    <option key={"team_opt_" + t.teamId} value={t.teamId}>
                      {t.teamName}
                    </option>
                  ))}
              </select>
              <button className="green medium ml-15" onClick={() => addTeam()}>
                <i className="material-icons">add</i>
              </button>
            </div>
            <div className="label-row">
              <label>Allowed teams</label>
              <div>
                {user.teams &&
                  user.teams.map((team, i) => (
                    <div key={"team_" + team} className="mt-5 inline-btn">
                      <button className="gray small mr-15" onClick={() => removeTeam(i)}>
                        <i className="material-icons">close</i>
                      </button>
                      {teams && teams.find((t) => t.teamId === team)?.teamName}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {(user.role === "supervisor" || user.role === "payAdvisor") && (
          <div>
            <h5>Assign User</h5>
            <div className="label-row">
              <label>Employee</label>

              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginBottom: 10 }}>
                  {user._employee !== undefined &&
                    listAutocomplete &&
                    listAutocomplete.find((item) => item.value?.toString() === user._employee?.toString())?.name}
                </div>

                <div className="autocomplete-wrapper">
                  <ReactSearchAutocomplete
                    items={listAutocomplete}
                    autoFocus
                    onSelect={(e) => {
                      setValue("_employee", parseInt(e.value));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left">
          <button
            className="gray"
            onClick={() => {
              setNewPassword("");
              setShowModalChangePwd(true);
            }}
          >
            Change Password
          </button>
        </div>
        <div className="btn-bar-right">
          <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={save}>
            {user.id ? "Update" : "Create User"}
          </button>
        </div>
      </div>

      {showModalChangePwd && (
        <Modal close={setShowModalChangePwd} size={"small"}>
          <div className="modal-header">Change Password</div>

          <div className="modal-body">
            <div className="label-row">
              <label>New Password</label>
              <input value={user.password} type="password" onChange={(e) => setNewPassword(e.target.value)}></input>
            </div>
          </div>

          <div className="modal-footer">
            <div className="btn-bar-left"></div>
            <div className="btn-bar-right">
              <button className="link" onClick={() => setShowModalChangePwd(false)}>
                Cancel
              </button>
              <button className="green" onClick={changePassword}>
                Change Password
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
