import { useEffect } from "react";
import SidebarRight from "./SidebarRight";
// import { useDebouncedCallback } from "use-debounce";

export default function SidebarFilterImport({ showSidebar, setShowSidebar, filter, setFilter, setFilterValue }) {
  // const [filtersDebounce, setFiltersDebounce] = useState(filter);

  useEffect(() => {
    // fetchFiltersMap();
    // eslint-disable-next-line
  }, []);

  // const setFilterValueDebounceFn = useDebouncedCallback((key, value) => {
  //   setFilterValue(key, value);
  // }, 300);

  // const setFilterValueDebounce = (key, value) => {
  //   let set = {};
  //   set[key] = value;
  //   setFiltersDebounce({ ...filter, ...set });
  //   setFilterValueDebounceFn(key, value);
  // };

  return (
    <SidebarRight show={showSidebar}>
      <div className="sidebar-header">
        <div className="sidebar-title">Filters</div>
        <div className="sidebar-title-right">
          <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
            Close
            <i className="material-icons">chevron_right</i>
          </button>
        </div>
      </div>
      <div className="filter-container">
        <div className="filter-row">
          <select required value={filter.year || ""} onChange={(e) => setFilterValue("year", e.target.value)}>
            <option value="" disabled>
              Year
            </option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("year", "")}>
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="filter-row">
          <select required value={filter.month || ""} onChange={(e) => setFilterValue("month", e.target.value)}>
            <option value="" disabled>
              Month
            </option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
            <option value="13">13th</option>
          </select>
          <button className="small btn-reset" onClick={() => setFilterValue("month", "")}>
            <i className="material-icons">close</i>
          </button>
        </div>

        {/* <div className="filter-row">
          <input
            type="text"
            placeholder="HR Code"
            value={filtersDebounce.hrCode}
            onChange={(e) => setFilterValueDebounce("hrCode", e.target.value)}
          />
          <button className="small btn-reset" onClick={() => setFilterValueDebounce("hrCode", "")}>
            <i className="material-icons">close</i>
          </button>
        </div> */}
      </div>
      <div className="sidebar-footer">
        <button onClick={() => setFilter({})}>
          <i className="material-icons">close</i>
          Reset
        </button>
      </div>
    </SidebarRight>
  );
}
